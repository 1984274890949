<template>
  <div class="container my-5">
    <swiper
      :options="swiperOptions"
      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
    >
      <swiper-slide>
        <b-row class="bg-white">
          <b-col cols="12" md="8" class="d-flex align-items-center">
            <div class="footerSliderLeft" v-if="lang == 'en'">
              <h3 class="heading1">Smart Analytics and Insights</h3>
              <p class="details">
                Access advanced analytics tools and gain valuable insights to
                make informed investment decisions
              </p>
            </div>
            <div class="footerSliderLeft" v-if="lang == 'ar'">
              <h3 class="heading1">تحليلات وبصريات ذكية</h3>
              <p class="details">
                تعزيز سبل الوصول إلى أدوات التحليل التحليلي واكتساب آراء قيمة
                لاتخاذ قرارات استثمارية مستنيرة
              </p>
            </div>
          </b-col>
          <b-col cols="12" md="4"><img class="w-100" :src="slides[0]" /></b-col>
        </b-row>
      </swiper-slide>

      <swiper-slide>
        <b-row class="bg-white">
          <b-col cols="12" md="8" class="d-flex align-items-center">
            <div class="footerSliderLeft" v-if="lang == 'en'">
              <h3 class="heading1">Experience the Future of Investing</h3>
              <p class="details">
                Embrace the power of technology and streamline your investment
                journey.
              </p>
            </div>
            <div class="footerSliderLeft" v-if="lang == 'ar'">
              <h3 class="heading1">تجربة مستقبل الاستثمار</h3>
              <p class="details">
                تمسك بقوة التكنولوجيا وابسط رحلتك الاستثمارية
              </p>
            </div>
          </b-col>
          <b-col cols="12" md="4"><img class="w-100" :src="slides[1]" /></b-col>
        </b-row>
      </swiper-slide>

      <swiper-slide>
        <b-row class="bg-white">
          <b-col cols="12" md="8" class="d-flex align-items-center">
            <div class="footerSliderLeft" v-if="lang == 'en'">
              <h3 class="heading1">
                Every Step Counts, Every Investment Matters
              </h3>
              <p class="details">
                Stay focused, be consistent, and watch your investments grow
                over time
              </p>
            </div>
            <div class="footerSliderLeft" v-if="lang == 'ar'">
              <h3 class="heading1">كل خطوة محسوبة، وكل مسألة استثمارية</h3>
              <p class="details">
                ابقوا مركزين، كونوا ثابتين، وراقبوا استثماراتكم تنمو بمرور الوقت
              </p>
            </div>
          </b-col>
          <b-col cols="12" md="4"><img class="w-100" :src="slides[2]" /></b-col>
        </b-row>
      </swiper-slide>

      <swiper-slide>
        <b-row class="bg-white">
          <b-col cols="12" md="8" class="d-flex align-items-center">
            <div class="footerSliderLeft" v-if="lang == 'en'">
              <h3 class="heading1">Dare to Dream Big</h3>
              <p class="details">
                Investing allows you to dream big and pursue your aspirations.
              </p>
            </div>
            <div class="footerSliderLeft" v-if="lang == 'ar'">
              <h3 class="heading1">التحدي الذي يواجه الحلم الكبير</h3>
              <p class="details">
                يَسْمحُ لك للحُلْم الكبيرِ ومتابعة تطلعاتِكَ.
              </p>
            </div>
          </b-col>
          <b-col cols="12" md="4"><img class="w-100" :src="slides[3]" /></b-col>
        </b-row>
      </swiper-slide>
    </swiper>
  </div>
</template>
<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import { BRow, BCol, BButton } from "bootstrap-vue";
export default {
  name: "FooterSlider",
  data() {
    return {
      lang: this.$store.state.locale.locale,
      slides: [
        require("@/assets/footerslide/aic/analytics.png"),
        require("@/assets/footerslide/aic/dream_big.png"),
        require("@/assets/footerslide/aic/every_step_counts.png"),
        require("@/assets/footerslide/aic/future_of_investing.png"),
      ],
      swiperOptions: {
        spaceBetween: 30,
        effect: "fade",
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        autoplay: {
          delay: 4000,
          disableOnInteraction: false,
        },
      },
    };
  },
  components: {
    Swiper,
    SwiperSlide,
    BRow,
    BCol,
    BButton,
  },
};
</script>
<style scoped>
.footerSliderLeft {
  padding: 100px;
}
.footerSliderLeft .heading1 {
  font-size: 4rem;
  font-weight: 600;
  color: #332983;
}
.footerSliderLeft .details {
  color: #49a9de;
  font-size: 2rem;
  line-height: 2.3rem;
}
.footerSliderLeft .heading2 {
  font-size: 3rem;
  color: #49a9de;
  font-weight: bold;
}
.footerSliderLeft .heading3 {
  font-size: 4rem;
  font-weight: 600;
  color: #332983;
}
/* write media query for mobile */
@media (max-width: 768px) {
  .footerSliderLeft {
    padding: 50px 25px;
  }
  .footerSliderLeft .heading1 {
    font-size: 2rem;
  }
  .footerSliderLeft .heading2 {
    font-size: 2rem;
  }
  .footerSliderLeft .heading3 {
    font-size: 2rem;
  }
}
</style>
