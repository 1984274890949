<template>
  <div class="project shadow">
    <b-row>
      <b-col sm="12" md="4">
        <b-link :to="`/profile/details/${project._id}`"
          ><div class="imgproject"><img :src="image" :alt="project.name" /></div
        ></b-link>
      </b-col>
      <b-col sm="12" md="8">
        <b-link :to="`/profile/details/${project._id}`"
          ><h3 class="projecttitle text-primary">{{ project.name }}</h3></b-link
        >
        <p>{{ project.highlights }}</p>
        <div class="projectmetas">
          <div class="projectmeta" v-if="project.price">
            <div class="label">{{ $t("projectCard.Price") }}</div>
            <div>{{ project.price.priceNumber }}</div>
          </div>
          <div class="projectmeta">
            <div class="label">{{ $t("projectCard.Project Budget") }}</div>
            <div>{{ project.project.budgetAmount }}</div>
          </div>
          <div class="projectmeta">
            <div class="label">{{ $t("projectCard.Staff Members") }}</div>
            <div>{{ project.project.staffNumbers }}</div>
          </div>
          <div class="projectmeta">
            <div class="label">{{ $t("projectCard.Blockchain Exchange") }}</div>
            <div>
              {{
                project.blockchainExchange
                  ? $t("projectCard.Yes")
                  : $t("projectCard.No")
              }}
            </div>
          </div>
        </div>

        <div class="mt-2">
          <b-button
            variant="primary"
            class="mr-2"
            :to="`/profile/details/${project._id}`"
            >{{ $t("projectCard.Details") }}</b-button
          >
          <b-button
            variant="outline-primary"
            :to="`/profile/${project.files[0].userId.username}`"
            >{{ $t("projectCard.User Profile") }}</b-button
          >
        </div>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import {
  BCard,
  BCardBody,
  BRow,
  BCol,
  BImg,
  BLink,
  BButton,
} from "bootstrap-vue";
import millify from "millify";
export default {
  components: {
    BCard,
    BCardBody,
    BCol,
    BRow,
    BImg,
    BLink,
    BButton,
  },
  props: {
    project: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      image: "",
    };
  },
  mounted: function () {
    this.image = this.project.files.find(
      (x) => x.name == "Profile Icon"
    ).fileUrl;
  },
  methods: {
    calcMonthlySales(number) {
      return `${millify(number, {
        precision: 1,
        decimalSeparator: ".",
        space: true,
      })} `;
    },
    arCalcMonthlySales(number) {
      return `${millify(number, {
        precision: 1,
        units: ["", "الف", "مليون", "مليار", "بليون"],
        decimalSeparator: ".",
        space: true,
      })} `;
    },
  },
};
</script>
<style>
.project {
  padding: 10px;
  border-radius: 10px;
  border: 1px solid #e5e7eb;
  background-color: #ffffff;
}
.project .imgproject {
  width: 100%;
  height: 250px;
  overflow: hidden;
  border-radius: 10px;
  border: 1px solid #e5e7eb;
  position: relative;
}
.project .imgproject img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.project .projecttitle {
  font-size: 20px;
  margin: 10px 0px;
}
.project .projectmetas {
  border-top: 1px solid #e5e7eb;
  display: flex;
  padding-top: 15px;
}
.project .projectmetas .projectmeta {
  border-right: 1px solid #e5e7eb;
  padding: 5px 20px;
  text-align: center;
}
.project .projectmetas .projectmeta:last-child {
  border-right: none;
}
.project .projectmetas .projectmeta .label {
  font-weight: bold;
  margin-bottom: 5px;
}
</style>
