<template class="mb-5">
  <div>
    <b-container>
      <h2 class="heading-newBlogs text-center mb-3 mt-5">
        {{ $t("index.Popular Categories") }}
      </h2>

      <!-- blogs -->
      <!-- Popular Categories -->
      <b-row>
        <b-col cols="12" class="text-center mb-4">
          <b-link
            to="/investor/list"
            v-for="(category, index) in categories"
            :key="index"
            class="btn btn-outline-primary btn-sm mr-1 mb-1"
            >{{ category }}</b-link
          >
        </b-col>
      </b-row>
      <!--/ Popular Categories -->

      <!--/ blogs -->
    </b-container>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";

import {
  BContainer,
  BRow,
  BCol,
  BCard,
  BFormInput,
  BCardText,
  BCardTitle,
  BMedia,
  BAvatar,
  BMediaAside,
  BMediaBody,
  BImg,
  BCardBody,
  BLink,
  BBadge,
  BFormGroup,
  BInputGroup,
  BInputGroupAppend,
  BPagination,
} from "bootstrap-vue";
import { kFormatter } from "@core/utils/filter";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    BContainer,
    BRow,
    BCol,
    BCard,
    BFormInput,
    BCardText,
    BCardBody,
    BCardTitle,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    BLink,
    BBadge,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BImg,
    BPagination,
  },
  data() {
    return {};
  },
  //   created() {
  //     this.$http.get("/blog/list/data").then((res) => {
  //       this.blogList = res.data;
  //     });
  //     this.$http.get("/blog/list/data/sidebar").then((res) => {
  //       this.blogSidebar = res.data;
  //     });
  //   },
  created() {},
  mounted() {
    // axios
    //   .get("posts/allBlogs/")
    //   .then((response) => (this.blogList2 = response.data.data));
    //this.getBlogs();
  },
  //   computed: {
  //     ...mapGetters({
  //       categories: "profile/getInvestorCategories",
  //     }),
  //   },
  computed: {
    categories() {
      // Assuming you have the categories computed property returning an array of objects
      return [
        "Corporate Investor / Buyer",
        "Bank",
        "Financial Institutation",
        "Accounting Firm",
        "Business Broker",
        "Financial Advisor",
        "Financial Consultant",
        "Investment Bank",
        "Law Firm",
        "M&A Advisor",
        "Merchant Bank",
        "Commercial Real Estate Broker",
      ];
    },
  },
  methods: {},
};
</script>
