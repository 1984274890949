<template>
  <b-container class="my-5">
    <b-row>
      <b-col
        ><h2 class="heading-topComany mb-0">{{ $t("index.Top Projects") }}</h2>
        <p>{{ $t("index.Top Projects Details") }}</p>
        <div v-if="isLoading">
          <b-skeleton animation="fade" width="100%" height="100px"></b-skeleton>
          <b-skeleton animation="fade" width="100%" height="100px"></b-skeleton>
          <b-skeleton animation="fade" width="100%" height="100px"></b-skeleton>
          <b-skeleton animation="fade" width="100%" height="100px"></b-skeleton>
          <b-skeleton animation="fade" width="100%" height="100px"></b-skeleton>
        </div>
      </b-col>
    </b-row>

    <b-row>
      <b-col
        cols="12"
        class="mb-2"
        v-for="(project, index) in projects"
        :key="`project${index}`"
      >
        <project :project="project" :key="index" />
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import axios from "axios";
import {
  BCard,
  BCardText,
  BButton,
  BContainer,
  BRow,
  BCol,
  BCardImg,
  BCardBody,
  BSkeleton,
} from "bootstrap-vue";
import Project from "./Project";
export default {
  components: {
    Project,
    BCard,
    BCardText,
    BButton,
    BContainer,
    BRow,
    BCol,
    BCardImg,
    BCardBody,
    BSkeleton,
  },
  data() {
    return {
      projects: [],
      isLoading: true,
    };
  },
  mounted() {
    axios.get("/projects/top").then((res) => {
      this.isLoading = false;
      this.projects = res.data.data;
    });
  },
};
</script>
