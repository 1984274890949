<template>
  <div>
    <Header class="header-home" />

    <SliderNew3D />
    <TopCompany />
    <TopInvestor />
    <TopFreelancer />
    <TopProjects />
    <CategoryHome />
    <FooterSlider />

    <NewBlog v-if="lang == 'en'" />

    <Footer />
  </div>
</template>

<script>
import Header from "@/layouts/landpage/HeaderHome.vue";
import SliderNew3D from "./SliderNew3D.vue";
import Trading from "@/layouts/landpage/Trading2.vue";
import TopCompany from "@/layouts/landpage/TopCompany3.vue";
import TopFreelancer from "@/layouts/landpage/TopFreelancer2.vue";
import TopInvestor from "@/layouts/landpage/TopInvestor2.vue";
import NftBlock from "@/layouts/landpage/NftBlock.vue";
import TopProjects from "./TopProjects.vue";
import NewBlog from "@/layouts/landpage/NewBlog.vue";
import CategoryHome from "@/layouts/landpage/CategoryHome.vue";
import FooterSlider from "./FooterSlider.vue";

import Footer from "@/layouts/landpage/Footer.vue";

import {
  BCard,
  BCardText,
  BButton,
  BContainer,
  BRow,
  BCol,
  BCardImg,
  BCardBody,
} from "bootstrap-vue";
import AppFooter from "@/@core/layouts/components/AppFooter.vue";
export default {
  components: {
    Header,
    Trading,
    TopCompany,
    TopFreelancer,
    TopInvestor,
    NewBlog,
    CategoryHome,
    BCard,
    BCardText,
    BButton,
    BContainer,
    BRow,
    BCol,
    BCardImg,
    BCardBody,
    Footer,
    NftBlock,
    SliderNew3D,
    FooterSlider,
    TopProjects,
  },
  data() {
    return {
      NFT_img: "",
      renderComponent: true,
      lang: this.$store.state.lang,
    };
  },
  methods: {
    forceRerender() {
      // Removing my-component from the DOM
      this.renderComponent = false;

      this.$nextTick(() => {
        // Adding the component back in
        this.renderComponent = true;
      });
    },

    setDefaultLanguage() {
      if (localStorage.getItem("language") == undefined) {
        localStorage.setItem("language", "en");
      }
    },
  },

  watch: {
    // whenever question changes, this function will run
    "$store.state.myCurrency.myCurrency": function () {
      // console.log("watch");
      // this.forceRerender();
    },
  },
  mounted() {
    this.setDefaultLanguage();
  },
};
</script>

<style scoped>
.posts h2 {
  font-size: 25px;
  line-height: 48px;
  margin: 32px 0;
  margin-left: 5px;
  margin-right: 5px;
  border-bottom: 1px solid #dcdcdc;
  width: 100%;
  padding-bottom: 15px;

  display: flex;
  align-items: center;
  justify-content: space-between;
}

.posts .view-all a {
  font-size: 16px;
  font-weight: 600;
}
.posts .card .card-img {
  height: 220px;
}
</style>

<style>
.Vue-Toastification__container {
  z-index: 55555;
}
</style>
